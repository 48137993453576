.App {
    text-align: center;
}

.App-header {
    background-color: #222;
    height: 50px;
    padding: 1px;
    color: white;
}

.App-title {
    font-size: 1em;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
